<template>
  <div>
    <a-form class="upload-form">
      <span>Внутренний справочник-каталог</span>
      <span>Дата обновления: 20.12.2020</span>
      <a-upload
        name="file"
        :action="apiUrl"
        :headers="headers"
        @change="handleChange"
      >
        <a-button type="primary" v-if="!response" class="file-upload">
          <a-icon type="upload" />
          Загрузить файл
        </a-button>
      </a-upload>
    </a-form>
    <div v-if="response" class="sentinfo">
      <a-result
        status="success"
        title="Файл успешно загружен!"
        sub-title="Загрузите прайс-листы Поставщиков, для добавления
        новых позиций в каталог"
      >
        <template #extra>
          <a-button @click="$router.push('/localprices')" type="primary">
            Перейти в прайс-листы Поставщиков
          </a-button>
        </template>
      </a-result>
    </div>
  </div>
</template>

<script>
import { API_PREFIX } from "@/services/api/v1/const"
export default {
  name: "Directories",
  data: () => ({
    apiUrl: `${API_PREFIX}/dealer-dictionary`,
    headers: null,
    response: null,
  }),
  mounted() {
    this.setRequestParams()
  },
  methods: {
    setRequestParams() {
      const token = localStorage.getItem("token")
      if (token) {
        this.headers = {
          Authorization: `Bearer ${token}`,
        }
      }
    },
    handleChange(info) {
      const status = info.file.status
      if (status !== "uploading") {
        console.log(info.file, info.fileList)
      }
      if (status === "done") {
        this.$message.success(`Файл успешно загружен.`)
        this.response = info.file.response
        console.log("this response", this.response)
      } else if (status === "error") {
        this.$message.error(`${info.file.name} ошибка загрузки.`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-form {
  display: flex;
  justify-content: space-around;
  align-items: center;

  margin-bottom: 10px;
  padding: 10px;

  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;

  .file-upload {
    width: 200px;
  }
}
</style>
